body {
  font-family: "Arial", sans-serif;
  background: transparent;
  color: #333;
  line-height: 1.6;
}

header {
  background: #fff;
  padding: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.hidden {
  display: none;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 3%;
}

.red {
  color: red;
}

.blue {
  color: blue;
}

.checkbox_false {
  background-color: #d7d7d7 !important;
}

.form-container {
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
  max-width: 650px;
  margin: 20px auto; /* Reduced the top and bottom margin to 20px */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  color: #9c9c9c;
}

/* Additional styles from the old code */
form label {
  display: block;
  margin-top: 20px;
  font-size: clamp(12px, 0.9vw, 14px);
  /* text-transform: capitalize; */
}
form .input_field {
  cursor: pointer;
  width: 100% !important;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  transition: 0.3s all ease;
}
form .input_field:hover {
  box-shadow: 6px 7px 3px 0 #0004;
}
form textarea {
  resize: vertical;
}

form .checkbox-label {
  display: flex;
  margin-top: 15px;
  font-weight: normal; /* Removes bold styling */
  font-size: 12px; /* Sets font size to 8px */
}
/* ======================================== */

.field_group {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  width: 100% !important;
}
.field_input--wrap {
  height: 6.5rem;
  max-width: 19rem;
  width: 100% !important;
}
input[type="checkbox" i] {
  margin-right: 0.5rem;
  padding: 0.5rem;
  width: 30px;
  display: block;
  height: 20px;
}
input[type="checkbox" i]:checked {
  border: 1px solid #fff;
}
.field_input--minwrap {
  max-width: 12rem;
  width: 100%;
}
.submit_button_wrapper {
  text-align: center;
  margin-top: 2rem;
}
.submit_button_wrapper input[type="submit"] {
  padding: 12px clamp(50px, 15vw, 200px);
  background-color: green;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 100px;
}
.required_badge {
  color: red;
  font-size: 12px;
}
@media screen and (max-width: 600px) {
  .field_input--minwrap {
    max-width: 19rem;
    width: 100%;
  }
}

.div_additional_breed {
  display: flex;
  align-items: end;
}

.input_submit {
  width: 100% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.pet-type {
  margin-top: 0px !important;
}

.none {
  pointer-events: none;
}

.breed_group {
  margin-bottom: 4%;
}

@media (max-width: 800px) {
  .field_input--wrap {
    height: auto;
    max-width: 19rem;
    width: 100%;
  }
}
